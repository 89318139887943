import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Class10Elucidation2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Class10/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Class10/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Class10/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Class10/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Class10/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Class10/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Class10/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Class10/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Class10/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Class10/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Class10/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Class10/12.webp`;
    
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 2,
            cols: 1,
        },

        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1.5,
            cols: 1,
        },

        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CLASS 10 BOARD EXAMINATION ELUCIDATION
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Date: 29 January 2025
                                </Typography>
                                {/* <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “A joy that is shared is the joy that is doubled” Class10, a festival of joy brings with itself the
                                    special message of hope, happiness and joy”<br />
                                </Typography> */}
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwantpur organized an online session for students and parents to elucidate the process for upcoming Board Examination for class 10 on 29 January 2025. This session aimed at providing vital instructions and guidelines to Class 10 students in preparation for the upcoming board exams.
                                    <br></br>
                                    The session was conducted by the Principal Ms. Sheeja Manoj, with an additional insightful talk by Dr. Paramesh, a renowned Doctor and also a parent of our school student. Together, they shared valuable advice on exam protocols, preparation strategies, time management, stress management and maintaining emotional well-being during this critical period. Our senior students also shared tips on the importance of time management, balancing studies with relaxation and staying motivated.
                                    <br></br>
                                    The session concluded with a note of encouragement from both the Principal and Dr. Paramesh. The Principal reiterated that success comes from consistent effort, self-belief and maintaining a balanced approach to both studies and personal well-being and also encouraged students to be kind to themselves, recognizing that their worth is not solely determined by exam results. The session was extremely helpful in preparing students mentally and emotionally for the upcoming exams. Students expressed gratitude for the practical advice offered and left the session feeling more confident and better equipped to face the challenges ahead.
                                    <br></br>

                                </Typography>

                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Education is the most powerful weapon which you can use to change the world" and "Success is the sum of small efforts, repeated day in and day out."


                                <br />

                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Class10Elucidation2024;